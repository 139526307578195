import React, { useContext } from 'react';
import { Link, navigate } from 'gatsby';
import { FirebaseContext } from '../Firebase';
import Menu from 'react-burger-menu/lib/menus/slide';

import './hamburger.css';

function Hamburger({ menuOpen, setMenuOpen }) {
    const { firebase, user } = useContext(FirebaseContext);
    
    // necessary to prevent infinite loop with state change
    // https://github.com/negomi/react-burger-menu/wiki/FAQ#i-want-to-control-the-open-state-programmatically-but-i-dont-understand-how-to-use-the-isopen-prop
    function handleStateChange (stateObj) {
        setMenuOpen(stateObj.isOpen);
    }

    function handleLogoutClick() {
        firebase.logout().then(() => {
            navigate('/signin')
            clickHandler()
        });
    }

    function clickHandler() {
        setMenuOpen(false)
    }

    return (
        <Menu
            width='225px'
            isOpen={menuOpen}
            // provide callback fn. It will give object with state of menu
            onStateChange={stateObj => handleStateChange(stateObj)}
            customBurgerIcon={false}
            disableAutoFocus
        >
            <Link to='/about' onClick={clickHandler}>About Tony</Link>
            <Link to='/pricing' onClick={clickHandler}>Pricing</Link>
            <Link to='/blog' onClick={clickHandler}>Blog</Link>
            <Link to='/support' onClick={clickHandler}>Support</Link>
            {/* Sign In/Out */}
            {(user && user.email)
                ? <Link to="#" onClick={handleLogoutClick}>Sign Out</Link>
                : <Link to='/signin' onClick={clickHandler}>Sign In</Link>
            }
        </Menu>
    );
}

export default Hamburger;