// from https://www.udemy.com/course/gatsby-js-firebase-hybrid-realtime-static-sites/
const app = import('firebase/app');
const auth = import('firebase/auth');
const database = import('firebase/firestore');
const functions = typeof window !== 'undefined' ? import('firebase/functions') : null;
const storage = import('firebase/storage');

// looks to me like this settles all promises and then returns the "app" which is thenable
const loadFirebaseDependencies = Promise.all([app, auth, database, functions, storage]).then(values => {
  return values[0].default;
});

export default loadFirebaseDependencies;