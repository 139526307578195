// from https://www.udemy.com/course/gatsby-js-firebase-hybrid-realtime-static-sites/
import firebaseConfig from "./config";
import { generateUserName } from '../../utilities/utilities';

function Firebase(app) {
	if(!firebaseInstance) {
		app.initializeApp(firebaseConfig);

		this.auth = app.auth();
		this.db = app.firestore();
		this.functions = app.functions();
		this.storage = app.storage();

		// for local function emulator:
		// this.functions.useFunctionsEmulator("http://localhost:5001");
	}

	// using cloud function for this so people can't setup multiple usernames.
	this.register = async function(details) {
		const {firstName, lastName, userName, level} = details
		const {vip, email, password, sendReg, testing} = details
		// 1. interact with auth() and make a new account for them.
		if (!testing) {
			await this.auth.createUserWithEmailAndPassword(email, password)
		}
			// .catch() // handle errors here
		// 2. create a link to firebase functions function createNewUser
		const createProfileCallable = this.functions.httpsCallable('createNewUser'); // this is name of firebase function
		// 3. call that firebase function, pass in payload, and return result.
		if (!testing) {
			await createProfileCallable({
				firstName,
				lastName,
				userName: userName || generateUserName(firstName, lastName),
				vip,
				level,
				email,
				sendReg,
			});
		}
		// should do error checks here

		// if sendReg, call registration email.
		if (sendReg) {
			const sendWelcomeEmail = this.functions.httpsCallable('sendWelcomeEmail');
			await sendWelcomeEmail({
				firstName,
				email,
				level,
				vip,
				password,
				testing, // skips auth to use cloud functions
			});
			// do error checks
			// can I confirm that email was sent?
		}
		return
	}

	// for testing purposes
	this.registerBasic = function({ email, password }) {
		return this.auth.createUserWithEmailAndPassword(email, password);
	}

	this.login = async function({email, password}) {
		return this.auth.signInWithEmailAndPassword(email, password);
	}

	this.logout = async function() {
		await this.auth.signOut();
	}

	this.getUserProfile = function({ userId, onSnapshot }) {
		// console.log('[getUserProfile - userId] ', userId);
		// console.log('[getUserProfile - userId.uid] ', userId.uid);
		return this.db.collection('userProfiles')
			.where('userId', '==', userId.uid) // added .uid because it seemed like I needed it. lets see if it works.
			.limit(1)
			.onSnapshot(onSnapshot); // this returns an unsubscribe function. onSnapshot subscribes to changes
	}

	this.uploadContent = async function(details) {
		const {title, summary, imageUrl, audioUrl} = details
		const {duration, date, file, target, testing} = details

		var collection, storageFolder
		if (target === 'dispatch') {
			collection = 'insightDispatch'
			storageFolder = 'insight_dispatch'
		} else if (target === 'synthesis') {
			collection = 'bookSyntheses'
			storageFolder = 'book_syntheses'
		}

		// audioUrl is read-only, so copy
		let audioUrlCopy = audioUrl

		// upload file to firebase storage
		let uploadLink
		if (file) {
			uploadLink = await this.storage.ref().child(storageFolder + '/' + file.name).put(file)
				.then(snapshot => {
					// console.log('first snapshot', snapshot.ref.getDownloadURL())
					return snapshot.ref.getDownloadURL()  // Will return a promise with the download link
				})
				.then(downloadURL => {
					console.log(`Successfully uploaded file and got download link - ${downloadURL}`);
					audioUrlCopy = downloadURL
					return downloadURL
				})
				.catch(error => {
					// Use to signal error if something goes wrong.
					console.log(`Failed to upload file and get link - ${error}`);
					return undefined
				});
			
		} else {
			console.log('File not included.')
		}

		// if testing, set document name to 'test'
		const doc = testing ? 'test' : undefined

		// post the data to database
		await this.db.collection(collection).doc(doc).set({
			title,
			summary,
			imageUrl,
			audioUrl: audioUrlCopy,
			duration,
			date
		})

		return uploadLink
	}
}


var firebaseInstance;

function getFirebaseInstance(app) {
	// if we don't have the firebase Instance yet, but we have the app depenency came through
	if(!firebaseInstance && app) {
		firebaseInstance = new Firebase(app);
		return firebaseInstance;
	// else if it exists, just return it. Don't do anything else.
	} else if (firebaseInstance) {
		return firebaseInstance
	// finally if there is no app dependency (or anything else goes wrong)
	} else {
		return null;
	}
}

export default getFirebaseInstance;
