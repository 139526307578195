import React, { useState } from 'react';
import styled from '@emotion/styled';

function TopAnnounce({ message, color, display=false }) {
    const [isOpen, toggleOpen] = useState(true);

    // thought I needed the following to store whether TA was open, but with Layout
    // in gatsby-ssr.js, it doesn't reload on page change.
    // const [isOpen, toggleOpen] = useState(sessionStorage.getItem('showTA?') || true);

    // useEffect(() => {
    //     sessionStorage.setItem('showTA?', isOpen);
    // }, [isOpen]);

    return (
        <CONTAINER display={display} color={color} show={isOpen}>
            <div></div>
            <MESSAGE>{message}</MESSAGE>
            <TOGGLE onClick={() => toggleOpen(!isOpen)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="Icon__StyledSVG-sc-14uxrhm-0 lhKFTg">
                    <path d="M 17 7 L 7 17 M 7 7 L 12.03 12.03 L 17 17" stroke="#999999" fill="transparent" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" />
                </svg>
            </TOGGLE>
        </CONTAINER>
    );
}

const CONTAINER = styled('div')`
    display: ${props => props.display && props.show ? 'grid' : 'none'};
    grid-template-columns: 1fr 3fr 1fr;
    justify-items: center;
    padding: 12px 0 9px 0;
    background-color: ${props => props.color};
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 15px;
    line-height: 1.1rem;
`;

const MESSAGE = styled('div')``;

const TOGGLE = styled('div')`
    height: 24px;
    width: 24px;
    cursor: pointer;

    &:hover {
        path {
            stroke: black;
        }
    }
`;

export default TopAnnounce;