
// inspired by https://github.com/ryanburgess/lines-to-paragraphs/blob/master/index.js
export function newLineToParagraphs(content) {
    return (
        '<p>' + content
        .replace(/\r?\n([ \t]*\r?\n)+/g, '</p><p>')
        .replace(/\r?\n/g, '<br />')
        + '</p>'
    );
};

export function colonSeparatedTimeToCasual(colonTime) {
    let casualTime = colonTime.split(':');
    return casualTime[0] + ' minute' + (casualTime[0] > 1 ? 's ' : ' ') + casualTime[1] + ' seconds';
}

export function generateUserName(firstName, lastName) {
    // this would be a great spot to do some testing
    // apostrophe's break it - need valdiation
    const fullName = (firstName + lastName).replace(/[^a-zA-Z]/g, '');
    const userName = fullName.toLowerCase();
    return userName;
}

export function dateStringToArgs(dateString) {
    const dateArr = dateString.split('-')
    if (dateArr[1] > 0) {
        dateArr[1] = dateArr[1] - 1
    }
    return new Date(...dateArr)
}