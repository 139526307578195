import React, { useState, useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled from '@emotion/styled';
import { FirebaseContext } from './Firebase';

import Hamburger from './Hamburger/Hamburger';

function TopNav() {
    const [menuOpen, setMenuOpen] = useState(false);
    const { firebase, user } = useContext(FirebaseContext);
    // console.log('[TopNav - user] ', user);


    function handleLogoutClick() {
        firebase.logout().then(() => navigate('/signin'));
    }

    return (
        <NAV>
            <Hamburger menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
            <ul>
                <HAM_IMG open={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
                    <span class="material-icons">menu</span>
                </HAM_IMG>
                <LOGO>
                    <Link to='/'>
                        <LOGO_IMG />
                    </Link>
                    <Link to='/'>
                        <span>Tony Bronzo</span>
                    </Link>
                </LOGO>
                <li><Link to='/about'>About Tony</Link></li>
                <li><Link to='/pricing'>Pricing</Link></li>
                <li><Link to='/blog'>Blog</Link></li>
                <li><Link to='/support'>Support</Link></li>
                {/* Sign In/Out */}
                {(user && user.email)
                    ? <li><Link to="#" onClick={handleLogoutClick}>Sign Out</Link></li>
                    : <li><Link to='/signin'>Sign In</Link></li>
                }
                {/* CTA */}
                {(user && user.email)
                    ? <li><Link to='/members'>Members</Link></li>
                    : <li><Link to='/pricing'>Sign up<span> for $9</span></Link></li>
                }
            </ul>
        </NAV>
    );
}

const NAV = styled('nav')`
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: bold;
    color: #969696;
    ${'' /* position: fixed; */}

    ul {
        display: flex;
        align-content: center;
        padding-left: 0; // Removes left padding from bullet
        padding: 0 1.0875rem;
        list-style: none;
        padding-top: 7px;
        margin-bottom: 0;

        @media (max-width: 735px) {
            justify-content: space-between;
            margin: 5px 5px 0;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        transition: color 0.3s ease-out;
    }

    a:hover {
        color: #FFF;
    }

    li {
        padding: 8px 20px;
        font-size: 15px;
    }

    // Sign In/Out
    li:nth-last-of-type(2) {
        color: #09F;
        padding-left: 2rem;
        padding-right: 2rem;
        cursor: pointer;
    }

    li:nth-last-of-type(2) a:hover {
        color: #09639E;
    }

    // Sign up button
    li:last-of-type {
        ${'' /* change this to var */}
        background: #09F;
        color: #FFF;
        border-radius: 50px;
        padding-left: 25px;
        padding-right: 25px;
        transition: background 0.3s ease-out;

        @media (max-width: 735px) {
            align-self: center;
            padding: 6px 15px;
            font-size: 0.85rem;
        }


        // to shorten content
        span {
            @media (max-width: 1035px) {
                display: none;
            }
        }
    }

    li:last-of-type:hover {
        background: #0AF;
    }

    // middle links -> not logo nor cta button
    li:nth-child(n+3):nth-child(-n+7) {
        @media (max-width: 735px) {
            display: none;
        }
    }

    // hamburger icon
    ul > div {
        display: none;

        span {
            transition: color 0.3s ease-out;
        }

        @media (max-width: 735px) {
            display: block;
            line-height: 50px;
            color: #FFF;

            span:hover {
                cursor: pointer;
                color: #969696;
            }
        }
    }
`;

const LOGO = styled('li')`
    flex-grow: 1;
    color: #FFF;
    padding-left: 0 !important;
    display: flex;

    a {
        display: flex;

        @media (max-width: 735px) {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    a:hover {
        color: #969696;
    }

    span {
        padding-left: 10px;

        @media (max-width: 820px) {
            display: none;
        }
    }

    @media (max-width: 735px) {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
`;

const LOGO_IMG = () => (
    <svg width="27" height="29" viewBox="0 0 27 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.61653 19.1489L3.24407 19.1952L13.7704 0.0140865L15.3386 3.10324L8.12422 16.2515L15.9531 16.2515L17.6824 19.1009L6.61653 19.1489Z" fill="#00CCFF"/>
        <path d="M20.939 19.7919L22.6108 22.7505L0.897565 22.7268L2.4441 19.8104L17.699 19.8611L13.8166 13.0284L15.4921 10.0339L20.939 19.7919Z" fill="#8855FF"/>
        <path d="M15.9068 4.02814L14.258 6.92927L8.98868 16.2922L12.2149 16.2922L15.9276 9.68988L23.3784 22.7691L26.6871 22.7691L15.9068 4.02814Z" fill="#00CC88"/>
    </svg>
);

const HAM_IMG = styled('div')`

        background-color: #3d3d3d;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        text-align: center;

        span {
            position: relative;
            right: 0.5px;
            bottom: 2px;
        }

`;

export default TopNav;