/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { FirebaseContext, useAuth } from '../Firebase';

import TopAnnounce from '../TopAnnounce';
import Header from "../header"
import Footer from '../Footer';
import "./layout.css"

import annoucement from '../../../content/data/site/TopAnnounce.yml';

function Layout({ children }) {
  // any time these change in useAuth, they'll change in layout and it will rerender.
  const { user, firebase, loading } = useAuth();

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <FirebaseContext.Provider value={{user, firebase, loading}}>
      <TopAnnounce 
        message={annoucement['message']}
        display={annoucement['display']}
        color={annoucement['color']}
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: '100%',
          padding: `0 0 1.45rem`, // was 1.0875rem (top sides bottom)
          background: `#181818`,
        }}
      >
        {children}
        <Footer />
      </div>
    </FirebaseContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
