import React, { useContext } from 'react';
import { Link, navigate } from 'gatsby';
import styled from '@emotion/styled';
import { FirebaseContext } from './Firebase';

function Footer() {
    const { user, firebase } = useContext(FirebaseContext);

    function handleLogoutClick() {
        firebase.logout().then(() => navigate('/signin'));
    }

    return (
        <FOOTER>
            <LINKS>
                <ul>
                    <li>Tony Bronzo</li>
                    <li><Link to ='/blog'>Blog</Link></li>
                    <li><Link to ='/pricing'>Pricing</Link></li>
                    {/* Sign In/Out */}
                    {(user && user.email)
                        ? <li><Link to="#" style={{cursor:'pointer'}} onClick={handleLogoutClick}>Sign Out</Link></li>
                        : <li><Link to='/signin'>Sign In</Link></li>
                    }
                </ul>
                <ul>
                    <li>Resources</li>
                    <li><Link to ='#'>Testimonials</Link></li>
                    <li><a href='https://freshchord.tonybronzo.com'>FreshChord</a></li>
                </ul>
                <ul>
                    <li>About</li>
                    <li><Link to ='/about'>About Tony</Link></li>
                    <li><Link to ='#'>Company</Link></li>
                    <li><Link to ='#'>Careers</Link></li>
                </ul>
                <ul>
                    <li>Support</li>
                    <li><Link to='#'>Survey</Link></li>
                    <li><Link to ='/support'>Contact</Link></li>
                </ul>
            </LINKS>
            <COPYRIGHT>
                Copyright © {new Date().getFullYear()} TonyBronzo.com
            </COPYRIGHT>
            <LEGAL_LINKS>
                <Link to='#'>Cookies</Link> · <Link to='#'>Privacy Policy</Link>
            </LEGAL_LINKS>
        </FOOTER>
    );
}

const FOOTER = styled('div')`
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 0 0;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;

    a {
        text-decoration: none;
        color: #FFF;
    }

    @media (max-width: 500px) {
        width: 95%;
    }
`;

const LINKS = styled('div')`
    align-self: stretch;
    display: flex;
    justify-content: space-between;
    
    ul {
        list-style: none;
        font-size: 13px;
    }

    // category title
    li:first-of-type {
        color: #FFF;
        font-size: 16px;
        font-weight: bold;
        padding-bottom: 1rem;

        @media (max-width: 400px) {
            font-size: 14px;
        }
    }

    li {
        margin-bottom: 0;
    }

    a {
        transition: color 0.2s ease-out;
        font-weight: 400;
    }

    a:hover {
        color: #00BBFF;
    }
`;

const COPYRIGHT = styled('div')`
    color: #FFF;
    font-size: 12px;
    font-weight: bold;
    padding-top: 60px;
`;

const LEGAL_LINKS = styled('div')`
    color: #FFF; // for the dot
    font-size: 11px;
`;

export default Footer;