import PropTypes from "prop-types"
import React from "react"

import TopNav from './TopNav';

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: '#181818',
      marginBottom: `0`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        // padding: `0 1.0875rem`, turned this off because the hamburger wasn't working
      }}
    >
      <TopNav />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
