// from https://www.udemy.com/course/gatsby-js-firebase-hybrid-realtime-static-sites/
// just a custom hook that provides firebase instance, and the currently signed in user
import { useEffect, useState } from "react"
import getFirebaseInstance from "./firebase"
import loadFirebaseDependencies from "./loadFirebaseDependencies"

function useAuth() {
    const [user, setUser] = useState(null)
    const [firebase, setFirebase] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let unsubscribe;
        let userProfileUnsubscribe;

        loadFirebaseDependencies.then(app => {
            const firebaseInstance = getFirebaseInstance(app)
             // this puts the firebase app into the component state. Now the components has a hold of the firebase instance.
            setFirebase(firebaseInstance)

            // we are watching/subscribed to any AuthStateChange[s]. If change, setUser state to the result of change.
            unsubscribe = firebaseInstance.auth.onAuthStateChanged(userResult => {
                // console.log('[useAuth - userResult] ', userResult);
                if (userResult) {
                    // getUserProfile queries firestore, matching auth ID with database ID
                    userProfileUnsubscribe = firebaseInstance.getUserProfile({
                        userId: userResult,
                        // setup function for onSnapshot subscription. This function runs on change
                        onSnapshot: result => {
                            // console.log('[onSnapshot, getUserProfile - result] ', result);
                            // forces refresh of JWT token, returns promise
                            firebaseInstance.auth.currentUser.getIdTokenResult(true)
                                // take that token and set it as the user's react state
                                .then(token => {
                                    // console.log('[getToken - token] ', token); // TODO get rid of
                                    // this sets the state for "user" that we'll return and use throughout the app
                                    setUser({
                                        ...userResult, // whatever we got from userResult
                                        isAdmin: token.claims.admin,  // this checks entire claims object for "admin"
                                        username: result.empty ? null : result.docs[0].id // id is called because we use username as the id/name of document
                                    });
                                });
                        }

                    });
                    // get user custom claims
                    /*setLoading(true);
                    Promise.all([
                        firebaseInstance.getUserProfile({ userId: userResult.uid }),
                        firebaseInstance.auth.currentUser.getIdTokenResult(true),
                    ]).then((result) => {
                        const publicProfileResult = result[0]
                        const token = result[1]

                        if (publicProfileResult.empty) {
                            publicProfileUnsubscribe = firebaseInstance.db
                              .collection("publicProfiles")
                              .where("userId", "==", userResult.uid)
                              .onSnapshot((snapshot) => {
                                  const publicProfileDoc = snapshot.docs[0]
                                  if (publicProfileDoc && publicProfileDoc.id) {
                                      setUser({
                                          ...userResult,
                                          admin: token.claims.admin,
                                          username: publicProfileDoc.id,
                                      })
                                  }

                                  setLoading(false)
                              })
                        } else {
                            const publicProfileDoc = publicProfileResult.docs[0]
                            if (publicProfileDoc && publicProfileDoc.id) {
                                setUser({
                                    ...userResult,
                                    admin: token.claims.admin,
                                    username: publicProfileDoc.id,
                                })
                            }

                            setLoading(false)
                        }
                    })*/
                } else {
                    setUser(null);
                }

                setLoading(false);
            })
        })

        return () => {
            if (unsubscribe) {
                unsubscribe()
            }

            if (userProfileUnsubscribe) {
                userProfileUnsubscribe()
            }
        }
    }, [])

    return { user, firebase, loading }
}

export default useAuth;
